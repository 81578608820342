export const SET_ALERT = "SET_ALERT";
export const NOTIFICATION = "NOTIFICTION";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_UPDATED = "USER_UPDATED";
export const AUTH_UPDATE = "AUTH_UPDATE";
export const AUTH_UPDATE_FAIL = "AUTH_UPDATE_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const GET_LOADS = "GET_LOADS";
export const LOADS_FETCHING = "LOADS_FETCHING";
export const RETURNED_SEARCHED_LOADS = "RETURNED_SEARCHED_LOADS";
export const RESET_SEARCHED_LOADS = "RESET_SEARCHED_LOADS";
export const GET_LOAD = "GET_LOAD";
export const INVOICE_LOAD_FETCHED = "INVOICE_LOAD_FETCHED";
export const LOAD_DOC_DELETE = "LOAD_DOC_DELETE";
export const INVOICE_CREATED = "INVOICE_CREATED";
export const LOAD_DOC_UPLOAD = "LOAD_DOC_UPLOAD";
export const UPDATE_LOAD = "UPDATE_LOAD";
export const UPDATE_LOAD_ERROR = "UPDATE_LOAD_ERROR";
export const LOAD_ERROR = "LOAD_ERROR";
export const ADD_LOAD = "ADD_LOAD";
export const DELETE_LOAD = "DELETE_LOAD";
export const PATCH_PICKUP = "PATCH_PICKUP";
export const PICKUP_ERROR = "PICKUP_ERROR";
export const PATCH_DROP = "PATCH_DROP";
export const DROP_ERROR = "DROP_ERROR";
export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVER = "GET_DRIVER";
export const DRIVER_ERROR = "DRIVER_ERROR";
export const ADD_DRIVER = "ADD_DRIVER";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const PATCH_DRIVER = "PATCH_DRIVER";
export const DELETE_DRIVER_LOAD = "DELETE_DRIVER_LOAD";
export const SELECT_LOAD = "SELECT_LOAD";
export const DELETE_LOAD_ERROR = "DELETE_LOAD_ERROR";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCEED = "FETCH_USERS_SUCCEED";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";
export const ADMIN_REG_USER = "ADMIN_REG_USER";
export const ADMIN_REG_USER_SUCCEED = "ADMIN_REG_USER_SUCCEED";
export const ADMIN_REG_USER_FAILED = "ADMIN_REG_USER_FAILED";
export const SELECT_USER_TO_EDIT = "SELECT_USER_TO_EDIT";
export const RESET_SELECTED_USER = "RESET_SELECTED_USER";
export const INIT_ADMIN_UPDATE_USER = "INIT_UPDATE_USER";
export const ADMIN_UPDATE_USER_SUCCEED = "UPDATE_USER_SUCCEED";
export const ADMIN_UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const INIT_ADMIN_DELETE_USER = "INIT_ADMIN_DELETE_USER";
export const ADMIN_DELETE_USER_SUCCEED = "ADMIN_DELETE_USER_SUCCEED";
export const ADMIN_DELETE_USER_FAILED = "ADMIN_DELETE_USER_FAILED";
export const OPEN_USER_MODAL = "OPEN_USER_MODAL";
export const CLOSE_USER_MODAL = "CLOSE_USER_MODAL";
export const MERGE_LOAD_DOCS = "MERGE_LOAD_DOCS";
export const RESET_INVOICE_GENERATED = "RESET_INVOICE_GENERATED";
export const FETCH_WAREHOUSES = "FETCH_WAREHOUSES";
export const FETCH_WAREHOUSEBYID = "FETCH_WAREHOUSEBYID";
export const WAREHOUSE_LOCATION = "WAREHOUSE_LOCATION";
export const GET_SHIPMENTS = "GET_SHIPMENTS"
export const GET_CHROBINSON_LOADS = "GET_CHROBINSON_LOADS"
export const CARRIER_PROFILE = "CARRIER_PROFILE"
export const DELETE_COMPONENT = "DELETE_COMPONENT"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"